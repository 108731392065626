import * as React from 'react'
import { StaticImage } from 'gatsby-plugin-image'
import Layout from '../components/layout'
import Seo from '../components/seo'
import * as styles from '../components/index.module.css'
import Translations from '../components/books'
import TranslationMisc from '../components/translated'
import Awards from '../components/awards'
import Roles from '../components/roles'

const IndexPage = () => (
  <Layout>
    <Seo title="Hanna Leliv"/>
    <div className="mx-auto">
      <div className={styles.textCenter}>
        <StaticImage
          src="../images/hanna_leliv.jpg"
          loading="eager"
          width={254}
          quality={95}
          formats={["auto", "webp", "avif"]}
          alt="Hanna Leliv"
          style={{marginBottom: `var(--space-3)`}}
          className={styles.circle}
        />
        <h1>Hanna Leliv</h1>
        <p>Literary translator from Lviv, Ukraine.</p>
      </div>
      <p>Hello and welcome to my personal page. I am a Ukrainian translator currently based in the US. I translate
        fiction and creative non-fiction, working between Ukrainian and English. Having given a Ukrainian voice to
        Hemingway and Ishiguro, I am now passionate about promoting Ukrainian literature abroad, with a special focus on
        women’s voices and children's books. Please feel free to <a href="mailto:hanna.leliv@gmail.com">email</a> me or
        send me a message on <a href="https://www.facebook.com/hanna.leliv" target="_blank" rel="noreferrer">Facebook</a>.</p>
      <h3>Qualifications</h3>
      <div>Translator-in-Residence, Princeton University, USA, 2023-24</div>
      <div>Leslie Center Faculty Fellow at the Leslie Center for the Humanities, Dartmouth College, USA, 2022-23</div>
      <div>Fulbright Fellow, MFA in Literary Translation, University of Iowa, USA, 2017-18</div>
      <div>MA in English Language and Literature, Lviv Ivan Franko National University, Ukraine, 2009-10</div>
      <div>BA in English Language and Literature, Lviv Ivan Franko National University, Ukraine, 2004-09</div>
    </div>
    <h3>Translations: Fiction and creative non-fiction</h3>
    <Translations />
    <div className="mx-auto">
      <TranslationMisc />
      <Awards />
      <Roles />
    </div>
  </Layout>
)

export default IndexPage
