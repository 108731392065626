import * as React from "react"
import books from '../../content/translations.json'

const Book = ({ title, description, imgSrc, src }) =>
  <a href={src} rel="noreferrer" target="_blank" className="no-underline" aria-label="book">
    <div className="py-5 bg-white px-5 shadow">
      <div>
        <img src={imgSrc} className="w-auto pl-0 ml-0 mr-auto mb-2" alt={title} />
      </div>
      <p className="leading-5 font-extrabold sm:leading-6 text-gray-800">{title}</p>
      <p className="text-sm text-gray-600 mb-0">{description}</p>
    </div>
  </a>

const Translations = () => (
  <div className="bg-white justify-center flex-col text-gray-800 min-h-screen flex">
    <div className="mx-auto lg:grid-cols-4 md:grid-cols-3 sm:grid-cols-2 grid gap-4">
      {books.map((book, idx) => <Book key={idx} {...book} />)}
    </div>
  </div>
)

export default Translations